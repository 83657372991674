import React from 'react';
import { makeStyles } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import LocationCityIcon from '@material-ui/icons/LocationCity';

const useStyles = makeStyles((theme) => ({
  optionRow: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: theme.spacing(1.75),
    padding: theme.spacing(0.75),
  },
  orIcon: {
    marginRight: theme.spacing(2),
    '& svg': {
      width: theme.spacing(2),
      color: theme.palette.grey[300],
    },
  },
  orName: {
    color: theme.palette.grey[600],
    // fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    display: 'block',
    margin: '0',
  },
  orType: {
    color: theme.palette.grey[300],
    // fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(10),
    margin: '0',
  },
}));

const PlaceRenderedOption = ({ option }) => {
  const classes = useStyles();

  const renderOptionIcon = (type) => {
    switch (type) {
      case 'airport':
        return <AirplanemodeActiveIcon />;
      case 'geography':
        return <LocationCityIcon />;
      default:
        return <LocationOnIcon />;
    }
  };

  return (
    <div className={classes.optionRow}>
      <div className={classes.orIcon}>{renderOptionIcon(option.placeable?.type)}</div>
      <div className="">
        <b className={classes.orName}>{option.name}</b>
        <span className={classes.orType}>{option.short_name}</span>
      </div>
    </div>
  );
};

export default PlaceRenderedOption;

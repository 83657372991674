import React from 'react';
import {
  makeStyles,
  Button,
} from '@material-ui/core';
import { withTranslation } from 'config/i18n';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { useLayoutLoadingSpinner, useLayoutSnackbar } from 'components/shared/Layout';

const useStyles = makeStyles((theme) => ({
  paperTopButton: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'start',
    padding: theme.spacing(2, 3),
    cursor: 'pointer',
    textTransform: 'initial !important',
    width: '100%',
  },
  ptbIcon: {
    color: theme.palette.common.black,
    width: theme.spacing(2),
    margin: '0',
    marginRight: theme.spacing(1.5),
  },
  ptbText: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    marginRight: theme.spacing(0.75),
  },
}));

const CurrentLocationButton = ({ t, onClick }) => {
  const classes = useStyles();
  const [, setLoading] = useLayoutLoadingSpinner();
  const [snackbar, setSnackbar] = useLayoutSnackbar();

  const getPosition = () => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
  });

  const getCurrentLocation = () => {
    setLoading(true);
    getPosition().then((position) => {
      onClick({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }).catch(() => {
      setLoading(false);
      setSnackbar({
        ...snackbar,
        open: true,
        severity: 'error',
        message: t('fe_es_geo_widget:place.location_blocked_msg'),
        duration: 10000,
      });
      // ER.Notifications.showErrorMessage("Geolocation is not supported by this browser.")
    });
  };

  return (
    <Button
      onMouseDown={getCurrentLocation}
      className={`${classes.paperTopButton}`}
    >
      <MyLocationIcon className={classes.ptbIcon} />
      <b className={classes.ptbText}>{t('fe_es_geo_widget:place.use_current_location')}</b>
    </Button>
  );
};

export default withTranslation(['fe_es_geo_widget'])(CurrentLocationButton);
